<template>
  <div>
    <b-sidebar
      id="add-suku-cadang-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="addSukuCadangSidebar"
      right
      no-header
      backdrop
      @hidden="(val) => changeValue(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ typeAmbil }} Suku Cadang
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <validation-observer
          ref="simpleRules"
          class="px-2"
        >
          <b-form>
            <b-row class="px-2">
              <b-col md="12">
                <b-form-group
                  label="Jumlah"
                  label-for="jumlah"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah"
                    rules="required"
                  >
                    <b-form-input
                      id="jumlah"
                      v-model="sukuCadangLocal.jumlah"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Jumlah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="typeAmbil === 'Tambah'"
                md="12"
              >
                <b-form-group
                  label="Harga (per item)"
                  label-for="harga"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Harga"
                    rules="required"
                  >
                    <b-form-input
                      id="harga"
                      v-model="sukuCadangLocal.harga"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Harga"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Bukti"
                  label-for="foto-bukti"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bukti"
                    rules="required"
                  >
                    <b-form-file
                      id="foto-bukti"
                      v-model="sukuCadangLocal.foto_bukti"
                      accept="image/*"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Pilih File"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submit()"
                >
                  Submit
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="changeValue(false)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BCol,
  BRow, BSidebar, BFormFile,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCol,
    BRow,
    BSidebar,
    BFormFile,
  },
  model: {
    prop: 'addSukuCadangSidebar',
  },
  props: {
    addSukuCadangSidebar: {
      type: Boolean,
      required: true,
    },
    sukuCadang: {
      type: Object,
      required: true,
    },
    typeAmbil: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      sukuCadangLocal: {
        id: null,
        jumlah: null,
        foto_bukti: null,
        type: null,
      },
    }
  },
  watch: {
    sukuCadang() {
      this.resetTaskLocal()
    },
  },
  methods: {
    resetTaskLocal() {
      this.sukuCadangLocal = this.sukuCadang
    },
    changeValue() {
      const data = {
        id: null,
        jumlah: null,
        foto_bukti: null,
      }
      this.$emit('suku-cadang-sidebar-change', data, false, this.typeAmbil)
    },
    submit() {
      this.sukuCadangLocal.type = this.typeAmbil
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('submit-data-suku-cadang', this.sukuCadangLocal)
        }
      })
    },
  },
}
</script>
<style>
.b-sidebar-body {
  overflow: hidden !important;
}
</style>
